<template>
	<div class="section_right">
		<!--section_right-->
		<div class="sub_top">
			<!--sub_top-->
			<div class="sub_title"><i class="far fa-exchange text-primary"></i>{{ $t('transfer.17')}}</div>
		</div>
		<div class="section_right_inner">
			


			<div class="s_left">
				<!--s_left-->
				<div class="withdrawal_section01">
					<!--withdrawal_section01-->
					<p class="withdrawal_section01_t"><span>{{ $t('transfer.2')}}</span></p>
					<!--total_box end-->
					<div name="packagefrm">
						<div class="withdrawal_input_box">
							<!--withdrawal_input_box-->
							<table style="width:100%;">
								<tbody>
                                    <tr>
										<td>{{$t('deposit.5')}}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="numberWithCommas(point)" readonly="">
											</div>
											
										</td>
									</tr>
                                    <tr>
										<td>{{ $t('withdraw_point.30') }}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" v-model="t_balance" @keyup="inputNumberFormat()" >
											</div>
											
										</td>
									</tr>
                                </tbody>
                            </table>
                            <table style="width:100%;">
								<tbody>
									<tr>
										<td>{{ $t('transfer.3')}}</td>
										<td>
											<select class="withdrawal_input01" v-model='coin' @change="ChangeCoin()">
												<option value='' style='display: none'>{{ $t('deposit.4') }}</option>
												<option :value='item.coin' style='' v-for="(item,index) in coin_list" :key="index">{{item.coin}}</option>
											</select>
										</td>
									</tr>
									<tr v-if="contract !=''">
										<td>{{net}} {{$t('deposit.5')}}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="base_balance" readonly="">
											</div>
											
										</td>
									</tr>
									<tr v-if="coin !=''">
										<td>{{ $t('assets.13')}}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="balance" readonly="">
											</div>
											
										</td>
									</tr>
                                    <tr v-if="coin !=''">
										<td>{{ $t('transfer.18')}}</td>
										<td>
											<div>
												<input type="text" name="addres" id="balance" class="withdrawal_input01" :value="e_balance" readonly="">
											</div>
											
										</td>
									</tr>
									<tr v-if="address !=''">
										<td>{{$t('deposit.6')}}</td>
										<td>
											<p class="qrcode text-center">
												<vue-qrcode :value="address" style="max-width:150px;" />
											</p>
											<div class="flex-between-center mb-3">
												<input type="text" name="addres" id="recaddr" class="index_input01" :value="address" readonly="">
												<div><i class="far fa-copy text-primary flex-shrink-0 chk_btn text-center"  onclick="copy_to_clipboard()"> {{ $t('landing.51')}}</i></div>
											</div>
											
										</td>
									</tr>
								</tbody>
							</table>
							<div><button type="button" id="next" class="btn-qrcode" @click="ExchangeConfirm()">{{ $t('transfer.1')}}</button></div>
						</div>
					</div>
				</div>
				<!--withdrawal_section01-->

				
			</div>
			
		</div>
		

		
	</div>
	<!--section_right end-->
</template>
<script>
const exactMath = require('exact-math');
const CryptoJS = require("crypto-js");
import VueQrcode from 'vue-qrcode'
	export default {
		components: {
			VueQrcode,
		},
		data(){
			return{
				address: '',
				balance : '',
				contract : '',
				base_balance : '',
				coin_list : [],
				sales_list : [],
				sales:'',
				coin : '',
				jejus_rate : '',
				rate : '',
				t_balance : '',
                point : '',
                e_balance : '',
				e_able : 'N',
			}
		},
	    mounted(){
			this.GetCoinInfo();
			this.GetSaleInfo();
			this.GetPointBalance();
			this.GetAble()
		},
		methods: {
			
			ChangeCoin(){
				this.GetWalletBalance()

			},
			GetAble(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/point/GetAble`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.e_able = body.info.exchange;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
            GetSaleInfo(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/sales/GetSaleList`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.sales_list = body.list;
								this.rate = body.info.balance;
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
            ExchangeConfirm(){
                const t_balance = this.t_balance;
                const balance = this.uncomma(t_balance);
                const e_balance = this.e_balance
                const point = this.point;
                const coin = this.coin;
				const e_able = this.e_able
				
				if(e_able =='N'){
					this.$alert(this.$t('transaction.61'));
                    return false;
				}

                if(balance ==''){
                    this.$alert(this.$t('transfer.14'))
                    return false;
                }
                const c_balance = exactMath.sub(point,balance);

                if(c_balance <0){
                    this.$alert(this.$t('transfer.14'))
                    return false;
                }

                if(coin ==''){
                    this.$alert(this.$t('transfer.19'))
                    return false;
                }

                if(balance <e_balance){
                    this.$alert(this.$t('transfer.20'))
                    return false;
                }

				
				this.Exchange();
                

            },
            Exchange(){
                const coin = this.coin;
                const t_balance = this.t_balance;
                const balance = this.uncomma(t_balance);
                
				const body = {coin,balance};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/point/Exchange`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
                                this.$alert(this.$t('transfer.21')).then(
                                    ()=>{
                                        this.$router.push({path:`/${this.$i18n.locale}/dashboard`})
                                    }
                                )


							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
				
            },
			GetPointBalance(){
				const coin = this.coin;
				const body = {coin};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/point/GetPointBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.point = body.point;

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
				
			},
            GetWalletBalance(){
				const coin = this.coin;
				const body = {coin};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/point/GetWalletBalance`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								this.balance = body.balance;
								this.address = body.address;
								this.contract = body.contract;
                                this.e_balance = body.e_balance;
                                this.net = body.net;
								this.base_balance = body.base_balance

							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
				
			},
			GetCoinInfo(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/point/GetCoinList`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)

								this.coin_list = body.list;
								

								
							}else if(res.data.code=='9999'){
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/login`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
            comma(str) {
                str = String(str);
                return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
            },
            uncomma(str) {
                str = String(str);
                return str.replace(/[^\d]+/g, '');
            },
            inputNumberFormat(){
                const price = this.t_balance;
                const uncomma = this.uncomma(price);
                this.t_balance = this.comma(uncomma);
            },
			numberWithCommas(x) {
				var parts=x.toString().split(".");
				return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
			},
		}
	}

</script>
<style>
</style>